.Galler{
    gap: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    padding: 40px 20px 0;
}
.Galler img{
    overflow: hidden;
    width: 100%;
    height: 200px;
    object-fit: cover;
    cursor: pointer;
}

.Caps{
    padding-top: 50px;
}


  @media screen and (max-width: 768px) {
    .Galler img{
        width: 100%;
    }
      .Galler{
          grid-template-columns: 1fr 1fr 1fr}
  }

  @media screen and (max-width: 480px) {
    .Galler img{
        width: 100%;
    }
      .Galler{
          grid-template-columns: 1fr}
  }
