.BenefitsWraper {
  margin: 0 auto;
  width: 1100px;
}

.BenefitsWraperIcon {
  width: 673px;
}

.BenefitsTitle1 {
  padding-top: 30px;
  padding-left: 10px;
  padding-bottom: 1px;
  text-align: start;
  font-family: Montserrat Alternates;
  font-style: normal;
  text-transform: none;
  font-weight: normal;
  font-size: 36px;
}

.BenefitsLine{
  background-color: black;
  height:1px;
  width: 330px;
  margin-bottom: 10px;
  margin-left: 9px;
}


/* .BenefitsTitle1::before {
  content: " ";
  width: 330px;
  height: 1px;
  margin-bottom: 100px;
  background-color: black;
  position: absolute;
  top: 855px;
} */

.BookingTitle {
  width:400px;
  font-family: Montserrat Alternates;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  position: relative;
}
.BookingLine{
  background-color: black;
  height: 1px;
  width: 330px;
  margin-top:5px;
  margin-left: 28px;
}

/*.BookingTitle::after {
  content: " ";
  width: 330px;
  height: 1px;
  margin-bottom: 100px;
  background-color: black;
  position: absolute;
  top: 0px;
  margin-left: 0px;
}*/

.BenefitsIcon {
  display: flex;

  margin: 9px;
  width: 318px;
  height: 69px;
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: rgb(196, 196, 196, 0.2);
  font-size: 18px;
  color: black;
}

.BenefitsIconWrapper {
  display: flex;
  flex-wrap: wrap;
}

.Tick {
  padding-right: 36px;
  padding-left: 19px;
}
.BenefitsWraper {
  padding-top: 0px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.BookingCardWrapper {
  width: 379px;
  
  
}

.BookingPrice {
  font-family: Montserrat Alternates;
  padding-top: 20px;
  font-style: normal;

  font-size: 19px;

  text-align: center;
}

.BookingCard {
  margin-top: 20px;
  width: 379px;
  height: 295px;
  
  border-radius: 5px;
  position: relative;
}

.allInBookLiving iframe {
  border: none;
  width: 423px;
  height: 350px;
  position: absolute;
  left: -20px;
  top: -15px;
  background-color: rgb(125, 217, 67, 0.65);
  border-radius: 5px;
}

@media screen and (max-width: 1150px ) {
  .BenefitsIcon{
    width: 40%;
  }
  .BookingCardWrapper{
    margin-left:-40px;
  }
  .BenefitsWraper{
    width: 94%;
  }
}


@media screen and (max-width: 1024px ) {
  .BookingTitle3WithBalcon{
    margin-top: 20px ;
  }
  .BookingCardWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
  }
  .BookingCard{
    
    width: 100%;
    overflow: hidden;
  }
  .BenefitsWraperIcon{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-left: 20px;
  }
  .BenefitsWraper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
  }
  .LuxeBenefitsWrapper{
    display: flex;
    flex-wrap: wrap;
    
  }
  .BenefitsIconWrapper{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
.BenefitsTitle1{
  width: 100%;
}


.TextWrapper{
  width: 100%;
}

.Text{
  padding-left:0px;
  width: 100%;
}


.LineText{
  width: 170px;
}

.BenefitsIcon{
  width: 45%;
}
}


@media screen and (max-width: 768px) {
  .BookingTitle3WithBalcon{
    margin-top: 20px ;
  }
  .BookingCardWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
  }
  .BookingCard{
    
    width: 100%;
    overflow: hidden;
  }
  .BenefitsWraperIcon{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-left: 20px;
  }
  .BenefitsWraper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
  }
  .LuxeBenefitsWrapper{
    display: flex;
    flex-wrap: wrap;
    
  }
  .BenefitsIconWrapper{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
.BenefitsTitle1{
  width: 100%;
}


.TextWrapper{
  width: 100%;
}

.Text{
  padding-left:0px;
  width: 100%;
}


.LineText{
  width: 170px;
}

.BenefitsIcon{
  width: 45%;
}
}

@media screen and (max-width: 480px) {
  .BenefitsIcon{
    width: 90%;
  }
.BookingTitle3WithBalcon{
    margin-top: 20px ;
  }
  .BookingCardWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    margin: 0 auto;
    width:100%;
  }
  .allInBookLiving{
    margin-left:10%;
  }
  .BookingCard{
    margin-left:-15px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    justify-content:center;
    
  }
  .BenefitsWraperIcon{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content:center;
    
  }
  .BenefitsWraper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width:100%;
    
  }
  .LuxeBenefitsWrapper{
    display: flex;
    flex-wrap: wrap;
    
  }
  .BenefitsIconWrapper{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
.BenefitsTitle1{
  width: 100%;
}


.TextWrapper{
  width: 100%;
}

.Text{
  padding-left:0px;
  width: 100%;
}


.LineText{
  width: 170px;
}
}