.tabs_block {
  margin: 0 auto;
  margin-top: 30px;
  font-size: 24px;
  display: flex;
  justify-content: center;
}
.active-tabs:after {
  content: "";
  display: block;
  height: 3px;
  width: 100%;
  background: transparent;
  border-radius: 2px;
  margin-bottom: -2px;
  background: #4fb2d1;
}

.tabs{
  cursor: pointer;
}

.blockWraper {
  max-width: 314px;
  display: flex;

  border-bottom: 1px solid #bdbdbd;
}

.content {
  display: none;
}

.active-content {
  display: flex;
}

.Probel {
  padding-left: 40px;
  padding-right: 30px;
}

.AllCards {
  margin: 0 auto;
}

.CardsWrapper {
  width: 1100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  height: 480px;
  background-color: #e0ecf3;
  margin-top: 50px;
}

.content_tabs {
  margin-bottom: 50px;
}

.Card .ImagesLiving {
  width: 500px;
  height: 315px;
}

.CardBattons {
  padding-top: 20px;
  font-weight: bold;
  display: none;
  justify-content: center;
}
.NameCard {
  display: flex;
  padding-top: 8px;
  text-align: center;
  justify-content: center;
  font-weight: 500;
  font-size: 22px;
}

.Card span {
  font-size: 16px;
  justify-content: center;
  display: flex;
  font-weight: 500;
}

.CardBattons button {
  margin-left: 30px;
  font-weight: bold;
  background-color: #5bbe1d;
  color: white;
  width: 180px;
  height: 45px;

  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  padding: 0px;
  border-radius: 4px;
}

.CardIconWrapper {
  width: 284px;
  display: flex;

  color: #57b0cc;
  padding-top: 25px;
  position: relative;
}

/* .CardIconWrapper::before{
    content: '';
    width: 337px;
    height: 2px;
    background-color: #57B0CC;
    position: absolute;
    margin-top: -5px;

} */

.IconText {
  white-space: nowrap;
  padding-left: 7px;
  padding-right: 23px;
  padding-top: 3px;
}

.BookCard {
  padding: 15px;
  font-weight: bold;
  background-color: #57b0cc;
  color: white;
  width: 240px;
  height: 45px;

  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  padding: 0px;
  border-radius: 4px;
}

.CardIconWrapper img {
  padding-top: 3px;
  position: relative;
}

.CardIconWrapper img :after {
  content: "";
  width: 250px;
  height: 2px;
  position: absolute;
  bottom: -20px;
  background-color: #57b0cc;
}



.Card:hover .CardBattons {
  display: flex;
}



.Card:hover .NameCard {
  display: none;
}

.Card:hover span {
  display: none;
}

.CardCotageMedia:hover .CardBattons {
  display: flex;
}



.CardCotageMedia:hover .NameCard {
  display: none;
}

.CardCotageMedia:hover span {
  display: none;
}

.CottegeWarpper {
  width: 1100px;
}

.CardBattons button:focus {
  vertical-align: middle;
  background-color: #e0ecf3;
  border: 3px solid grey;
  outline: none;
  color: grey;
}

.CardLux {
  position: relative;
  width: 284px;
  display: flex;
  padding-left: 84px;
  color: #57b0cc;
  padding-top: 25px;
  padding-left: 10px;
}

.IconLine {
  top: 18px;
  left: -20px;
  position: absolute;
  margin-top: -10px;

  width: 337px;
  height: 2.5px;
  background-color: #57b0cc;
}

.CardLux img {
  width: 22px;
  height: 22px;
}

.IconIcons {
  display: flex;
}
IconIcons2 {
  display: flex;
}

.IconWrapper1 {
  display: flex;
}
.Wrap {
  display: flex;
  margin-left: -20px;
}


.CardCotageMedia {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;

  background-color: #e0ecf3;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  overflow: hidden;
}
/*----------*/

@media screen and (max-width: 1200px) {
  .CardsWrapper {
    margin: 0 auto;
    max-width: 100%;
  }
  .AllCards {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .AllCards {
    width: 100%;
  }

  .CardsWrapper {
    max-width: 100%;
  }
  .Card {
    margin-top: 20px;

    overflow: hidden;
  }
  .IconText {
    font-size: 15px;
  }
}

@media screen and (max-width: 768px) {
  .AllCards {
    max-width: 768px;
  }
  .CardsWrapper {
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .swiper-container{
    margin-top: 0px;
  }

  .CardCotage1 {
    padding-left: 25px;
    width: 500px;
  }
  .ImagesLiving {
    margin-top: -27px;
  }

  .IconIcons {
    margin-right: 15px;
    margin-top: 3px;
  }
  .IconWrapper1 {
    margin-left: 20px;
    margin-top: -10px;
    width: 270px;
    flex-wrap: wrap;
  }
  .Wrap {
    display: flex;
    margin-left: 0px;
  }

  .IconIcons {
    margin-top: 3px;
  }

  .CardLux {
    width: 410px;
    flex-wrap: wrap;
    margin-left: 45px;
  }
  .Card {
    display: flex;
    flex-direction: column;
    justify-content: center;

    min-height: 460px;
    overflow: hidden;
  }

  .CardCotageMedia {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;

    background-color: #e0ecf3;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
    overflow: hidden;
  }

  .IconLine {
    padding-left: 0px;
    left: 75px;
    width: 250px;
  height: 2.5px;
  background-color: #57b0cc;
  }
  .IconText {
    font-size: 14px;
    padding-right: 10px;
  }
  .IconText img {
    padding-top: 20px;
  }

  .AllCards {
    max-width: 100%;
  }
  .CardsWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .content_tabs {
    width: 100%;
  }
  .IconLineLux {
    top: 18px;
    left: 35px;
    position: absolute;
    margin-top: -10px;

    width: 250px;
    height: 2.5px;
    background-color: #57b0cc;
  }
  .IconLine {
    display: none;
  }
  .BackgroundWrapper {
    max-height: 200px;
  }
  .Fixer{
    margin: 0px;
  }
}

.IconWrapperBig1 {
  width: 440px;
  display: flex;
  margin-left: 10px;
  flex-wrap: wrap;
}

.IconWrapperBig {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 450px;
}

.IconLineLux {
  left: 50px;
}

.IconIcons {
  margin: 3px;
}

.IconLineLux1 {
  left: -20px;
}

.IconWrapperBig12 {
  width: 470px;
  display: flex;
  margin-left: 10px;
  flex-wrap: wrap;
}

.Fix {
  color: #57b0cc;
  display: flex;
}

.Fix img {
  width: 22px;
  height: 22px;
}

.Fixer {
  color: #57b0cc;
  display: flex;
  margin-top: 6px;
  margin-left: 45px;
}

.Fixer img {
  width: 22px;
  height: 22px;
}

.CardCotage1 {
  margin-left: 85px;
  width: 500px;
}

.IconLineLux12 {
  margin-left: 60px;
}

.CardsWrapper {
  margin: 0 auto;
}
