@import url(https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Montserrat Alternates' ,'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Scriptic{
  display: none;
}
.KeyForBook{
  position: fixed;
  z-index: 500;
}

div{
    font-family: 'Montserrat Alternates';
    display: block;
}
.ButtoWraper button {
    background-color: white;
    width: 42px;
    height: 42px;
  border: 1px solid #9BC0D6;
  padding: 8px;
  font-family: "Roboto";
  font-weight: bold;
}

.ButtonLanguage {
  margin-right: 10px;
}

.ButtonLanguage:hover {
  background-color: #9BC0D6;
  color: white;
}

#something:target {
  background-color: black;
  color: white;
}


button:focus{
    background-color: black;
    color: white;
 }

.burgerOk{
  display:none;
  top: 17px;
  left: 30px;
  margin-top: 1%;
}


@media screen and (max-width: 768px) {
  .burgerOk{
    display:flex;
    position: fixed;
    z-index: 200;
  }
}

@media screen and (max-width: 480px) {
  .burgerOk{
    display:flex;
  }
}

.nav{
    background-color: #9BC0D6;
    max-height: 58px;
    padding-bottom: 20px;
    
}


.text{
    color: black;
    font-size: 13.8px;
    text-align: center;
    padding-top: 5px;
    font-size: medium;
    margin-bottom: 0px;
}

.footerMenu{
    text-align: center;
    font-size: 13.8px;
    margin:0 auto;
}

.footerSimpleLinks{
    display: inline-block;
    padding-right: 20px;
}
.footerSimpleLinks::before{
    content: "";
    background-color: #566F7D;
    width: 25px;
    height: 1px;
    position: absolute;
    margin-top: 20px;
}

a{
    color: black;
    text-decoration: none;
}
.specialForService::before{
    content: "";
    background-color: #566F7D;
    width: 25px;
    height: 1px;
    margin-top: 20px;
}

@media screen and (max-width: 480px) {
  .nav{
    max-width: 480px;
    max-height: 150px;
  }
}

@media screen and (max-width: 768px) {
  .nav{
    max-width: 768px;
    max-height: 100px;
  }
}

@media screen and (max-width: 1024px ) {
  .nav{
    max-width: 992px;
    max-height: 80px;
  }
}
div .UpperFooterPar {
  display: flex;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}

div .UpperFooterContent {
  display: flex;
  justify-content: space-between;
}

.UpperFooterContacts img {
  width: 24px;
  height: 24px;
  color: black;
}

.UpperFooterItem {
  max-width: 1163px;
  margin: 0 auto;
}

.FooterItems {
  margin-left: auto;
  margin-right: auto;
}

.FooterItems::before {
  content: "";

  background-color: #566f7d;
  width: 1.5px;
  height: 150px;
  position: absolute;
  margin-top: 135px;
  margin-left: 400px;
  opacity: 80%;
}
.FooterItems:last-child:before {
  content: "";
  opacity: 0%;
}

.TextContacts {
  max-width: 312px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  text-transform: none;
}

.TextContacts {
  display: flex;
  align-items: flex-start;
  margin-left: 16px;
  margin-bottom: 10px;
}

.TextContacts.div {
  display: block;
}

.fixCenter {
  margin-left: -15px;
}

.TextContacts img {
  height: 22px;
  width: 22px;
  margin-right: 5px;
}

.BookingImg {
  height: 60%;
  width: 80%;
  margin-right: 2%;
}

.PlanetHotel {
  height: 60%;
  width: 60%;
}

.PlanetImg {
  display: flex;
  align-items: center;
}

.IconFooter {
  display: flex;
  justify-content: space-between;
  width: 100px;
}

.Icons img {
  padding-top: 15px;
  width: 35px;
  height: 35px;
  padding-right: 20px;
}

.Icons:first-child {
  padding-left: 10px;
}

/*--------------------*/
.AllInUpperFooter {
  background-color: hsl(204, 71%, 97%);
  padding-top: 0px;
  padding-bottom: 20px;
}

.UpperFooterWrapper {
  margin: 0 auto;
  max-width: 1163px;
  display: flex;
}

.UpperFooterIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33.3%;
}

.FixKurw {
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  /* margin-top: 30px;
  margin-left: 110px;
  margin-bottom: 20px; */
}
.Centertext {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  text-align: center;
}

.TextContaxtWrapper {
  margin-left: 0px;
  position: relative;
}
.WrapperTextLiving {
  margin-left: -60px;
}

/*-------Адаптив--*/
@media screen and (max-width: 480px) {
  .UpperFooterWrapper {
    max-width: 480px;
    display: flex;
    flex-wrap: wrap;
  }
  .UpperFooterIcon {
    width: 100%;
  }
  .FixKurw {
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
  }

  .TextContacts {
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    margin-left: 16px;
    margin-bottom: 10px;
  }
  .TextContacts a {
    font-size: 17px;
  }
  .WrapperTextLiving {
    margin-left: -60px;
  }
}

@media screen and (max-width: 700px) {
  .UpperFooterWrapper {
    max-width: 480px;
    display: flex;
    flex-wrap: wrap;
  }
  .UpperFooterIcon {
    width: 100%;
  }
  .nav {
    max-width: 768px;
    max-height: 110px;
  }
  .TextContacts {
    font-size: 14px;
  }

  .TextContacts a {
    font-size: 14px;
  }

  
}


@media screen and (max-width: 900px) {
  
  .nav {
    max-width: 768px;
    max-height: 110px;
  }
  .TextContacts {
    font-size: 14px;
  }

  .TextContacts a {
    font-size: 14px;
  }

  
}

@media screen and (max-width: 1024px) {
  .nav {
    max-width: 992px;
    max-height: 110px;
  }
}

.allInContact2{
    width: 40%;
    height: 40%;
}

.allInCon2{
    padding-top: 30px;
    padding-bottom: 30px;
}


.allInCon2{
    display: flex;
    max-width: 1279px;
    justify-content: space-around;
    margin: 0 auto;

}

.infoContact2{
    width: 40%;
    height: 40%;
    background-color: rgb(125,217,67,0.8);
    border-radius: 15px;
    
}


.infoContact2{
    color: white;
    font-size: 34px;
    
}

.TextContacts2{
    font-size: 22px;
    display: flex;
    padding-bottom: 25px;
}

.MainContText2{
    padding-bottom: 30px;
    padding-top: 30px;
    text-align: center;
}

.TextContaxtWrapper2{
    margin-left: 5%;
    position: relative;
    
}

.UpperFooterContacts2{
    padding-right: 5%;
}

@media screen and (max-width: 1024px ) {
    .allInCon2{
        flex-direction: column;
        align-items: center;
    }
    .infoContact2{
        margin-bottom: 10px;
        width: 60%;
    height: 60%;
    }
    .TextContaxtWrapper2{
        margin-left: 15%;
        
        
    }
    .allInContacts{
        max-width: 1024px;
        
    }
    .allInContact2{
        width: 60%;
        height: 60%;
    }

  }
  
  @media screen and (max-width: 768px) {
    .allInCon2{
        margin-top: 0px;
        flex-direction: column;
    }
    .infoContact2{
        margin-bottom: 20px;
        width: 70%;
    height: 70%;
    }
    .TextContaxtWrapper2{
        margin-left: 5%;
        
        
    }
    .allInContacts{
        max-width: 768px;
        
    }
    .allInContact2{
        width: 70%;
        height: 70%;
    }
  }
  
  @media screen and (max-width: 480px) {
    .allInCon2{
        flex-direction: column;
        margin-top: -30px;
        
    }
    .infoContact2{
        width: 90%;
    height: 90%;
        margin-bottom: 10px;
    }
    .allInContact2{
        width: 90%;
        height: 90%;
    }
    .allInContacts{
        max-width: 480px;
        
    }
  }
.ImgBackground2 {
    padding-top: 10px;
        margin-top: -115px;
         
    }
    .Imgas2 {
      margin: 0 auto;
    }
    
    .Background2 {
      width: 100%;
      height: 80%;
    }
    
    .LogoDiv2 img {
        width: 100%;
      margin-top: 175px;
      position: relative;
      
    }
    .LogoDiv2{
        margin: 0 auto;
        width: 20%;
        height:112px;
        
    }
    
    .BackgroundWrapper{
      height: 250px;
      max-width: 100%;
      overflow: hidden;
    }
.Galler{
    grid-gap: 40px;
    gap: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    padding: 40px 20px 0;
}
.Galler img{
    overflow: hidden;
    width: 100%;
    height: 200px;
    object-fit: cover;
    cursor: pointer;
}

.Caps{
    padding-top: 50px;
}


  @media screen and (max-width: 768px) {
    .Galler img{
        width: 100%;
    }
      .Galler{
          grid-template-columns: 1fr 1fr 1fr}
  }

  @media screen and (max-width: 480px) {
    .Galler img{
        width: 100%;
    }
      .Galler{
          grid-template-columns: 1fr}
  }

.tabs_block {
  margin: 0 auto;
  margin-top: 30px;
  font-size: 24px;
  display: flex;
  justify-content: center;
}
.active-tabs:after {
  content: "";
  display: block;
  height: 3px;
  width: 100%;
  background: transparent;
  border-radius: 2px;
  margin-bottom: -2px;
  background: #4fb2d1;
}

.tabs{
  cursor: pointer;
}

.blockWraper {
  max-width: 314px;
  display: flex;

  border-bottom: 1px solid #bdbdbd;
}

.content {
  display: none;
}

.active-content {
  display: flex;
}

.Probel {
  padding-left: 40px;
  padding-right: 30px;
}

.AllCards {
  margin: 0 auto;
}

.CardsWrapper {
  width: 1100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  height: 480px;
  background-color: #e0ecf3;
  margin-top: 50px;
}

.content_tabs {
  margin-bottom: 50px;
}

.Card .ImagesLiving {
  width: 500px;
  height: 315px;
}

.CardBattons {
  padding-top: 20px;
  font-weight: bold;
  display: none;
  justify-content: center;
}
.NameCard {
  display: flex;
  padding-top: 8px;
  text-align: center;
  justify-content: center;
  font-weight: 500;
  font-size: 22px;
}

.Card span {
  font-size: 16px;
  justify-content: center;
  display: flex;
  font-weight: 500;
}

.CardBattons button {
  margin-left: 30px;
  font-weight: bold;
  background-color: #5bbe1d;
  color: white;
  width: 180px;
  height: 45px;

  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  padding: 0px;
  border-radius: 4px;
}

.CardIconWrapper {
  width: 284px;
  display: flex;

  color: #57b0cc;
  padding-top: 25px;
  position: relative;
}

/* .CardIconWrapper::before{
    content: '';
    width: 337px;
    height: 2px;
    background-color: #57B0CC;
    position: absolute;
    margin-top: -5px;

} */

.IconText {
  white-space: nowrap;
  padding-left: 7px;
  padding-right: 23px;
  padding-top: 3px;
}

.BookCard {
  padding: 15px;
  font-weight: bold;
  background-color: #57b0cc;
  color: white;
  width: 240px;
  height: 45px;

  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  padding: 0px;
  border-radius: 4px;
}

.CardIconWrapper img {
  padding-top: 3px;
  position: relative;
}

.CardIconWrapper img :after {
  content: "";
  width: 250px;
  height: 2px;
  position: absolute;
  bottom: -20px;
  background-color: #57b0cc;
}



.Card:hover .CardBattons {
  display: flex;
}



.Card:hover .NameCard {
  display: none;
}

.Card:hover span {
  display: none;
}

.CardCotageMedia:hover .CardBattons {
  display: flex;
}



.CardCotageMedia:hover .NameCard {
  display: none;
}

.CardCotageMedia:hover span {
  display: none;
}

.CottegeWarpper {
  width: 1100px;
}

.CardBattons button:focus {
  vertical-align: middle;
  background-color: #e0ecf3;
  border: 3px solid grey;
  outline: none;
  color: grey;
}

.CardLux {
  position: relative;
  width: 284px;
  display: flex;
  padding-left: 84px;
  color: #57b0cc;
  padding-top: 25px;
  padding-left: 10px;
}

.IconLine {
  top: 18px;
  left: -20px;
  position: absolute;
  margin-top: -10px;

  width: 337px;
  height: 2.5px;
  background-color: #57b0cc;
}

.CardLux img {
  width: 22px;
  height: 22px;
}

.IconIcons {
  display: flex;
}
IconIcons2 {
  display: flex;
}

.IconWrapper1 {
  display: flex;
}
.Wrap {
  display: flex;
  margin-left: -20px;
}


.CardCotageMedia {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;

  background-color: #e0ecf3;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  overflow: hidden;
}
/*----------*/

@media screen and (max-width: 1200px) {
  .CardsWrapper {
    margin: 0 auto;
    max-width: 100%;
  }
  .AllCards {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .AllCards {
    width: 100%;
  }

  .CardsWrapper {
    max-width: 100%;
  }
  .Card {
    margin-top: 20px;

    overflow: hidden;
  }
  .IconText {
    font-size: 15px;
  }
}

@media screen and (max-width: 768px) {
  .AllCards {
    max-width: 768px;
  }
  .CardsWrapper {
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .swiper-container{
    margin-top: 0px;
  }

  .CardCotage1 {
    padding-left: 25px;
    width: 500px;
  }
  .ImagesLiving {
    margin-top: -27px;
  }

  .IconIcons {
    margin-right: 15px;
    margin-top: 3px;
  }
  .IconWrapper1 {
    margin-left: 20px;
    margin-top: -10px;
    width: 270px;
    flex-wrap: wrap;
  }
  .Wrap {
    display: flex;
    margin-left: 0px;
  }

  .IconIcons {
    margin-top: 3px;
  }

  .CardLux {
    width: 410px;
    flex-wrap: wrap;
    margin-left: 45px;
  }
  .Card {
    display: flex;
    flex-direction: column;
    justify-content: center;

    min-height: 460px;
    overflow: hidden;
  }

  .CardCotageMedia {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;

    background-color: #e0ecf3;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
    overflow: hidden;
  }

  .IconLine {
    padding-left: 0px;
    left: 75px;
    width: 250px;
  height: 2.5px;
  background-color: #57b0cc;
  }
  .IconText {
    font-size: 14px;
    padding-right: 10px;
  }
  .IconText img {
    padding-top: 20px;
  }

  .AllCards {
    max-width: 100%;
  }
  .CardsWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .content_tabs {
    width: 100%;
  }
  .IconLineLux {
    top: 18px;
    left: 35px;
    position: absolute;
    margin-top: -10px;

    width: 250px;
    height: 2.5px;
    background-color: #57b0cc;
  }
  .IconLine {
    display: none;
  }
  .BackgroundWrapper {
    max-height: 200px;
  }
  .Fixer{
    margin: 0px;
  }
}

.IconWrapperBig1 {
  width: 440px;
  display: flex;
  margin-left: 10px;
  flex-wrap: wrap;
}

.IconWrapperBig {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 450px;
}

.IconLineLux {
  left: 50px;
}

.IconIcons {
  margin: 3px;
}

.IconLineLux1 {
  left: -20px;
}

.IconWrapperBig12 {
  width: 470px;
  display: flex;
  margin-left: 10px;
  flex-wrap: wrap;
}

.Fix {
  color: #57b0cc;
  display: flex;
}

.Fix img {
  width: 22px;
  height: 22px;
}

.Fixer {
  color: #57b0cc;
  display: flex;
  margin-top: 6px;
  margin-left: 45px;
}

.Fixer img {
  width: 22px;
  height: 22px;
}

.CardCotage1 {
  margin-left: 85px;
  width: 500px;
}

.IconLineLux12 {
  margin-left: 60px;
}

.CardsWrapper {
  margin: 0 auto;
}

.swiper-container {
    width: 100%;
    padding-top: 2%;
    padding-bottom: 5%;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 200px;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
  }
.BenefistWrapper{
  max-width: 750px;
  display:flex;
  margin: 0 auto;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 50px;
}


.firstJardWrapper{
 display: flex;
}

.SimpleJard img{
  margin-top: 10px;
}

.SimpleJard h1{
  font-size: 18px;
  margin-top: 10px;
}

.SimpleJard{
  display: flex;
  justify-content: center;
  align-items: center;
 width: 200px;
 height: 200px;
 background-color: white;
 text-align: center;
 position: relative;
 -webkit-transition: all 0.5s ease;
   -moz-transition: all 0.5s ease;
   -o-transition: all 0.5s ease;
}

.SimpleJard:hover{
  border: 1px solid rgb(188, 214, 248);
  transform: scale(1.05); 
  opacity: 100%;
 z-index: 50;
 box-shadow: 0px 0px 20px rgba(177, 234, 248, 0.8);

}

@media screen and (max-width: 480px) {
  .SimpleJard{
    width: 135px;
 height: 135px;
  }
  .SimpleJard img{
    width:65px;
  }
  .SimpleJard h1{
    font-size: 12px;
  }
}

@media screen and (max-width: 380px) {
  .SimpleJard{
    width: 105px;
 height: 105px;
  }
  .SimpleJard img{
    width:50px;
  }
  .SimpleJard h1{
    font-size: 10px;
  }
}



.MainWordsInBack{
   font-size: 40px;
   font-weight:400;
   opacity: 100%;
   color: white;
   display:block;
   margin-bottom: 20%;
}

.WeatherInBook2{
  position: absolute;
  left:10%;
  top: 15%;
  z-index: 200;
}


.firstEl{
    margin-right: 60%;
    opacity: 0%;
    animation: an 10s infinite;
}

.SecondEl{
  opacity: 0%;
  animation: an2 10s infinite;
}

.ThreeEl{
  margin-left: 60%;
  opacity: 0%;
  animation: an3 10s infinite;
}

@keyframes an {
  0% {
    opacity: 0%;
  }
  15% {
    opacity:100%
  }
  100% {
    opacity: 100%
  }
}
@keyframes an2 {
  0% {
    opacity: 0%;
  }
  15% {
    opacity: 0%;
  }

  30% {
    opacity:100%
  }
  100% {
    opacity: 100%
  }
}
@keyframes an3 {
  0% {
    opacity: 0%;
  }
  30% {
    opacity:0%
  }
  45% {
    opacity:100%
  }
  100% {
    opacity: 100%
  }
}

@media screen and (max-width: 1024px ) {
  .MainWordsInBack{
    font-size: 20px;
  }

}

@media screen and (max-width: 768px) {
  .MainWordsInBack {
    font-size: 18px;
    margin-bottom: 10%;
  }
}

@media screen and (max-width: 480px) {
  .MainWordsInBack {
    font-size: 10px;
  }
}


.et{
  max-width: 1297px;
}

 .icon-style {
  font-size: 100px;
  color: rgb(255, 255, 255);
}




i:hover {
 
  animation: shake 1.5s;

}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
 
  30% { transform: translate(3px, 2px) rotate(0deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
}


.Temperature h2{
  color:white;
  font-size: 18px;
  margin-left: 30%;
}

.Temperature i{
  margin-top: 2%;
}

@media screen and (max-width: 1024px) {
  .Temperature h2{
    display: none;
  }
  .Temperature i{
    display: none;
  }
   }

.CardSlider img {
  width: 420px;
  height: 280px;
}

.CardSlider {
  margin-bottom: 30px;
  text-align: center;
}

.AllInSliderMain {
  margin: 0 auto;
  max-width: 1440px;
  display: block;
  padding-bottom: 10px;
  padding-top: 50px;
}

.CottegesAndBlabLa {
  text-align: center;
  font-weight: 600;
  font-size: 28px;
  text-transform: uppercase;
  padding-bottom: 50px;
}

.WordsInSliderMain {
  text-align: center;
  width: 400px;
  padding-top: 5px;
}

.SliderForSmall {
  display: none;
}

.SliderForSmall123 {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.MargineSlider{
  margin: 0 auto;
}

.SliderForSmallest {
  display: none;
}

@media screen and (max-width: 1024px) {
  .SliderForBig {
    display: none;
  }

  .SliderForSmallest {
    display: none;
  }
  .SliderForSmall {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .SliderForBig {
    display: none;
  }
  .SliderForSmallest {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .SliderForSmall {
    display: none;
  }

  

}

@media screen and (max-width: 768px) {
  .SliderForBig {
    display: none;
  }

  .SliderForSmallest {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .SliderForSmall {
    display: none;
  }
}

.AboutUsWrapper {
  width: 1297px;
  
  padding-top: 2%;
  padding-bottom: 2%;
  display: flex;
  justify-content: space-between;
}

.containerrr {
  padding-top: 70px;
  margin: 0 auto;
  max-width: 1297px;
}

.AboutUsTextWrapper {
  padding:30px;
  background-color: #EEF9FF;
  
  
  
  width: 50%;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AboutUsTitle {
  margin-top: 20px;
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
}



.AboutUsImg{
  width: 50%;
  
  overflow: hidden;
  padding: 0px;
}
@media screen and (max-width: 1300px) {
  
    
  .AboutUsWrapper{
    display: flex;
    
    width: 100%;
  }
  .AboutUsTextWrapper{
    width: 100%;
  }
  .AboutUsImg{
    width: 100%;  
  }

}



@media screen and (max-width: 1024px) {
  .containerrr{
    padding-top: 0px;
  }
  .AboutUsWrapper{
    padding-top: 0%;
  }
    
  .AboutUsWrapper{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .AboutUsTextWrapper{
    width: 100%;
    
  }
  .AboutUsImg{
    width: 100%;  
  }
  .AboutUsTextWrapper{
    padding-top: 0px ;
  }
}

@media screen and (max-width: 768px) {
  .containerrr{
    padding-top: 0px;
    
  }
  .AboutUsTitle {
    font-size: 21px;
  }
  .AboutUsWrapper img {
    width: 100%;
    height: 80%;
  }
  .AboutUsTextWrapper {
    
    width: 100%;
    padding-left: 3% ;
    padding-right: 3%;
    font-size: 17px;
  }
  .AboutUsWrapper {
    flex-wrap: wrap;
    padding-top: 0%;
  }
  .AboutUsTitle{
      margin-top: 1%;
      margin-bottom: 1%;
  }
  .AboutUsTextWrapper{
    padding-top: 0px ;
  }
  .AboutUsWrapper{
    padding-top: 0%;
  }
  .AboutUsTextWrapper{
    padding-top: 0px ;
  }
}

@media screen and (max-width: 480px) {
  .containerrr{
    padding-top: 0px;
  }
  .AboutUsTextWrapper{
    padding-top: 0px ;
  }
  .AboutUsTitle {
    font-size: 21px;
  }
  .AboutUsWrapper {
    flex-wrap: wrap;
    padding-top: 0%;
  }
  .AboutUsTextWrapper {
    
    
    font-size: 14px;
  }
  .AboutUsWrapper{
    padding-top: 0%;
  }
  .AboutUsTextWrapper{
    padding-top: 0px ;
  }
}


.textAboutUs{
  white-space: pre-wrap;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.MainSwiperReview {
  width: 40%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 50px;
}

.SlideReview {
  background-color: #eef9ff;
  padding-top: 5% ;
  width: 100%;
  height: 400px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.SlideReview img {
  border-radius: 50%;
}

.SlideReview p {
  margin-top: 1%;
  font-size: 18px;
  margin-left: 2%;
}

.ReviewTitle {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 600;
}

.SlideText {
    text-align: left;
  width: 80%;
  margin: 0 auto;
  font-size: 15px;

}


.SlidTitleAva{
    font-size: 22px;
    font-weight: bold;
}


@media screen and (max-width: 1024px) {
  .MainSwiperReview {
    width: 576px;
  }
}

@media screen and (max-width: 768px) {
  .MainSwiperReview {
    width: 480px;
  }
}

@media screen and (max-width: 480px) {
  .MainSwiperReview {
    width: 100%;
  }
}

.RecomendWrapper{
  margin: 0 auto;
  text-align: center;
  margin-bottom: 50px;
}

.RecomendWrapper img{
  width: 10%;
  height: auto;
}

.RecomendWrapper img:not(:first-child){
  margin-left: 4%;
}

.ReviewTitle{
  padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .RecomendWrapper img{
    width: 15%;
    height: auto;
  }
}

@media screen and (max-width: 480px) {
  .RecomendWrapper img{
    width: 20%;
    height: auto;
  }
}
.firstPar {
  background-color: rgb(196, 196, 196, 0.5);
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  height: 60px;
  display: flex;
}

.secondPar {
  background-color: rgb(196, 196, 196, 0.5);
  margin-top: 30px;
  margin-bottom: 30px;
  height: 60px;
  display: flex;
  justify-content: flex-end;
}

.firstPar p {
  font-size: 30px;
  color: #000000;
  opacity: 70%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 40px;
}

.secondPar p {
  font-size: 30px;
  color: #000000;
  opacity: 70%;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 40px;
}

.allContact {
  margin: 0 auto;
  max-width: 1279px;
  margin-bottom: 30px;
}

.TextInService {
  height: 500px;
  width: 50%;
  background-color: rgb(196, 196, 196, 0.2);
  font-size: 19px;
  padding-left: 90px;
  padding-top: 30px;
}

.imageService {
  width: 50%;
  height: 500px;
}

.ServiseWrapper {
  display: flex;
}

/* Адаптив  */

.textServ {
  max-width: 400px;
}

.Sauna2 {
  margin-top: 20px;
}

/*Адаптив---------------*/
@media screen and (max-width: 1024px) {
  .TextInService{
    padding-top: 5%;
    padding-left: 5%;
    min-height: 100%;
  }
  .textServ{
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .ServiseWrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .TextInService {
    width: 100%;
    padding-left: 70px;
    padding-top: 40px;
    padding-bottom: 40px;
    height: 100%;
    
  }
  .textServ{
    max-width: 600px;
    
      width: 95%
    
  }
  .imageService {
    width: 100%;
    height: 100%;
  }
  .LongTextFirstPar{
    height: 100px;
  }
}

@media screen and (max-width: 480px) {
  .BackgroundWrapper{
    height: 100%;
  }
  .firstPar p {
    margin-left: 20px;
    font-size: 25px;
    
  }
  .firstPar {
    margin-top: 10px;
    margin-bottom: 10px;
    height:100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .secondPar {
    margin-top: 10px;
    margin-bottom: 10px;
    
  }
.textServ{
  font-size: 15px;
  width: 95%
}
  .secondPar p {
    font-size: 25px;
  }
  .ServiseWrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .TextInService {
    width: 100%;
    padding-left: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
    height: 100%;
  }
  .imageService {
    width: 100%;
    height: 100%;
  }
  .LongTextFirstPar{
  
    height:100%;
    padding: 3px;
    padding-left: 20px;
  }
}

.TextWrapper {
  width: 1100px;
  
  background-color: rgb(196, 196, 196, 0.2);
  padding: 55px;
  padding-bottom: 30px;

  margin: 0 auto;
  margin-bottom: 50px;
  margin-top: 40px;
  position: relative;
}

.Text {
  width: 980px;
  position: relative;
  padding-left: 20px;
}

.Text::before {
  content: " ";
  width: 0.5px;
  height: 80px;
  background-color: black;
  position: absolute;
  margin-left: -14px;
  margin-top: 8px;
}


/*.Text::after {
    content: " ";
    width: 360px;
    height: 0.5px;
    background-color: black;
    position: absolute;
    margin-left: -614px;
    top: -10px;
  }*/
  
  .LineText{
    width: 360px;
    height: 0.5px;
    background-color: black;
    position: absolute;
    margin-left: 40px;
    top: 38px;
  }
.BenefitsWraper {
  margin: 0 auto;
  width: 1100px;
}

.BenefitsWraperIcon {
  width: 673px;
}

.BenefitsTitle1 {
  padding-top: 30px;
  padding-left: 10px;
  padding-bottom: 1px;
  text-align: start;
  font-family: Montserrat Alternates;
  font-style: normal;
  text-transform: none;
  font-weight: normal;
  font-size: 36px;
}

.BenefitsLine{
  background-color: black;
  height:1px;
  width: 330px;
  margin-bottom: 10px;
  margin-left: 9px;
}


/* .BenefitsTitle1::before {
  content: " ";
  width: 330px;
  height: 1px;
  margin-bottom: 100px;
  background-color: black;
  position: absolute;
  top: 855px;
} */

.BookingTitle {
  width:400px;
  font-family: Montserrat Alternates;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  position: relative;
}
.BookingLine{
  background-color: black;
  height: 1px;
  width: 330px;
  margin-top:5px;
  margin-left: 28px;
}

/*.BookingTitle::after {
  content: " ";
  width: 330px;
  height: 1px;
  margin-bottom: 100px;
  background-color: black;
  position: absolute;
  top: 0px;
  margin-left: 0px;
}*/

.BenefitsIcon {
  display: flex;

  margin: 9px;
  width: 318px;
  height: 69px;
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: rgb(196, 196, 196, 0.2);
  font-size: 18px;
  color: black;
}

.BenefitsIconWrapper {
  display: flex;
  flex-wrap: wrap;
}

.Tick {
  padding-right: 36px;
  padding-left: 19px;
}
.BenefitsWraper {
  padding-top: 0px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.BookingCardWrapper {
  width: 379px;
  
  
}

.BookingPrice {
  font-family: Montserrat Alternates;
  padding-top: 20px;
  font-style: normal;

  font-size: 19px;

  text-align: center;
}

.BookingCard {
  margin-top: 20px;
  width: 379px;
  height: 295px;
  
  border-radius: 5px;
  position: relative;
}

.allInBookLiving iframe {
  border: none;
  width: 423px;
  height: 350px;
  position: absolute;
  left: -20px;
  top: -15px;
  background-color: rgb(125, 217, 67, 0.65);
  border-radius: 5px;
}

@media screen and (max-width: 1150px ) {
  .BenefitsIcon{
    width: 40%;
  }
  .BookingCardWrapper{
    margin-left:-40px;
  }
  .BenefitsWraper{
    width: 94%;
  }
}


@media screen and (max-width: 1024px ) {
  .BookingTitle3WithBalcon{
    margin-top: 20px ;
  }
  .BookingCardWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
  }
  .BookingCard{
    
    width: 100%;
    overflow: hidden;
  }
  .BenefitsWraperIcon{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-left: 20px;
  }
  .BenefitsWraper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
  }
  .LuxeBenefitsWrapper{
    display: flex;
    flex-wrap: wrap;
    
  }
  .BenefitsIconWrapper{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
.BenefitsTitle1{
  width: 100%;
}


.TextWrapper{
  width: 100%;
}

.Text{
  padding-left:0px;
  width: 100%;
}


.LineText{
  width: 170px;
}

.BenefitsIcon{
  width: 45%;
}
}


@media screen and (max-width: 768px) {
  .BookingTitle3WithBalcon{
    margin-top: 20px ;
  }
  .BookingCardWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
  }
  .BookingCard{
    
    width: 100%;
    overflow: hidden;
  }
  .BenefitsWraperIcon{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-left: 20px;
  }
  .BenefitsWraper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
  }
  .LuxeBenefitsWrapper{
    display: flex;
    flex-wrap: wrap;
    
  }
  .BenefitsIconWrapper{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
.BenefitsTitle1{
  width: 100%;
}


.TextWrapper{
  width: 100%;
}

.Text{
  padding-left:0px;
  width: 100%;
}


.LineText{
  width: 170px;
}

.BenefitsIcon{
  width: 45%;
}
}

@media screen and (max-width: 480px) {
  .BenefitsIcon{
    width: 90%;
  }
.BookingTitle3WithBalcon{
    margin-top: 20px ;
  }
  .BookingCardWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    margin: 0 auto;
    width:100%;
  }
  .allInBookLiving{
    margin-left:10%;
  }
  .BookingCard{
    margin-left:-15px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    justify-content:center;
    
  }
  .BenefitsWraperIcon{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content:center;
    
  }
  .BenefitsWraper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width:100%;
    
  }
  .LuxeBenefitsWrapper{
    display: flex;
    flex-wrap: wrap;
    
  }
  .BenefitsIconWrapper{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
.BenefitsTitle1{
  width: 100%;
}


.TextWrapper{
  width: 100%;
}

.Text{
  padding-left:0px;
  width: 100%;
}


.LineText{
  width: 170px;
}
}
    .BookingTitle3WithBalcon{
        font-family: Montserrat Alternates;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 49px;
        text-align: center;
    }
    


/*-------------------
Стилі до заголовків на різних сторінках
(Без них вони криві :)))  )
*/
      .BookingTitleMans{
        font-family: Montserrat Alternates;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 49px;
        text-align: center;
    }





      .BookingTitleCoteg{
        font-family: Montserrat Alternates;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 49px;
        text-align: center;
    }


.navHead{
    position: relative;
    height: 70px;
    max-width: 1279px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    background-color: white;
}


.HeaderMenu{
    padding-top:23.5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 75px;
}

.headerLinks{
    padding-right: 35px;
}

.phoneAndLaung{
    /*background-color: violet;*/

    padding-top:14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 12px;
}

.phone{
    border: 1px solid #bdbdbd;
    width: 42px;
    height: 42px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    display: flex;
  align-items: center;
  justify-content: center;
  margin-left:-1px;
}

.phone:hover{
    border:rgb(125,217,67,0.65);
}

.phone div{
    font-family: 'Roboto', sans-serif;
    width: 44px;
    height: 42px;
    display: flex;
  align-items: center;
  justify-content: center;
}



.phoneAndLaung img{
    width: 24px;
    height: 24px;
}


.headerLinks .ActivePage::after{
    content: '';
    display: block;
    height: 3px;
    width: 100%;
    background: transparent;
    border-radius: 2px;
    background: #4FB2D1;
}

.headerLinks .ActivePage{
    font-weight:450;
}

.super{
    display: inline-block;
    position: relative;
    padding-bottom: 3px;
}

.super:after {
    content: '';
    display: block;
    height: 3px;
    width: 0px;
    background: transparent;
    border-radius: 2px;
    transition: width .4s ease, background-color .4s ease;
  }

  .super:hover:after {
    width: 100%;
    background: #4FB2D1;
  }

  li{
      list-style: none;
      font-family: 'Roboto', sans-serif;
  }
  ul{
      display: flex;
      font-family: 'Roboto', sans-serif;
    justify-content: space-around;
  }

  .Laung{
      width :88px;
      height: 42px;
      border-top: 1px solid black;
      border-bottom:  1px solid black;
      margin-top: 0px;
      padding-left: 0px;
      display: none;

  }
  .bortik{
      border-right: 1px solid black ;
      width :44px;
      height: 41px;
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
      display: flex;
  align-items: center;
  justify-content: center;
  }
  .animat{
      display: flex;
      padding-right: 88px;
  }

  .animat:hover .Laung{
    display: flex;
  }

.animat:hover{
    padding-right: 0px;
}

li:hover{
   background-color: rgb(125,217,67,0.65);
}

li div{
    color:#707070;
}

li:hover div{
    color:white;
}

.animatPhone .phone div {
   width: 44px;
   height: 42px;
}

.animatPhone {
    margin-left: 20px;
    display:flex;
}

.Nomer{
    display:none;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid rgb(125,217,67,0.65);
    color:#313131;
    border-left: none;
    height: 42px;
}

.animatPhone:hover .Nomer{
    display: flex;
}

.animatPhone:hover{
    padding-right: 0px;
}

.Language_tell{

    display: flex;
    align-items: center;
    margin-left:10px;
}




.select{
    display: block;
    max-width: 215px;
    width: 100%;
    position: relative;
    border: 2px solid black;

    padding-top:8px;
    padding-bottom:11px;
    cursor: pointer;

}

.select .option:hover {
    background: black !important;
  }


  @media screen and (max-width: 480px) {
    .phoneAndLaung{
      display:none;
    }
    .HeaderMenu{
        display:none;
      }
      .ButtoWraper{
      }
      .navHead{
        justify-content: flex-end;
    }

  }

  @media screen and (max-width: 768px) {
    .phoneAndLaung{
        display:none;
    }
    .HeaderMenu{
        display:none;
      }
      .ButtoWraper{
    }
    .navHead{
        justify-content:flex-end;
    }

  }

  @media screen and (max-width: 1024px ) {
    .navHead{
        max-width: 1000px;

    }
    .phoneAndLaung{
       display:none;
    }
    .headerLinks{
        font-size: 12px;
    }
    .HeaderMenu{
        padding-left: 10px;
    }
    .headerLinks{
        padding-right: 20px;
    }
  }


  .styleForBurger{
  position: relative;
  display: block;
  text-align: left;
  max-width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 16%;
  background-position: 88% 50%;
  background-size: 36px;
  background-repeat: no-repeat;
  transition: background-position 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  text-decoration: none;
  color: #fff;
  font-size: 32px;
  line-height: 120%;
  font-weight: 500;
  }

  .styleForBurger:hover {
    background-position: 90% 50%;
  }

.menu {

  }
  

  .menu-item {
    border-bottom: 1px solid #9BC0D6;
  }
  
  .menu-item-a {
    display: block;
    padding: .7rem 15px;
    color: inherit;
    text-decoration: none;
  }
  
  .menu-item-a:hover {
    color: inherit;
    text-decoration: none;
    background-color: #eee;
  }
  
  .menu-item-a:focus {
    text-decoration: none;
  }
  
  .menu-item-a:visited {
    color: inherit;
  }
  
  .active-menu-item {
    background-color: #9BC0D6;
  }
  
  .hint {
    padding: .5rem 15px;
  }

  .menu-item a {
    display: block;
    padding: .7rem 15px;
    color: inherit;
    text-decoration: none;
  }
  
  .menu-item a:hover {
    color: inherit;
    text-decoration: none;
    background-color: #eee;
  }
  
  .menu-item a:focus {
    text-decoration: none;
  }
  
  .menu-item a:visited {
    color: inherit;
  }
.KeyForBook{
    width: 150px;
    height: auto;
}

.KeyForBook img{
    position: fixed;
    z-index: 500;
    bottom: 30px;
    right: 30px;
    width: 80px;
    height: auto;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;

  }

.KeyForBook img:hover{
    transform: scale(1.05);

}


@media screen and (max-width: 768px) {
    .KeyForBook{
        width: 100px;
        height: auto;
    }
    .KeyForBook img{
        width: 75px;
        height: auto;
        right: 20px;
      }
      .KeyForBook img:hover{
          transform: scale(1.05);

    }
  }

  @media screen and (max-width: 480px) {
    .KeyForBook{
        width: 80px;
        height: auto;
    }
    .KeyForBook img{
        width: 60px;
        height: auto;
        right: 15px;
      }
      .KeyForBook img:hover{
        width: 70px;

    }
  }


.BookingFlameWrapper{
    background-image: url(/static/media/ForAll.bf797325.jpg);
    background-repeat:no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display:block;
    margin: 0 auto;
    justify-content: center;
}

.BookingFlameWrapper .LogoForBooking{
    display:flex;
    margin: 0 auto;
    justify-content: center;
    padding: 50px 0 25px 0 ;
}

.BookingFrame{
    display:flex;
    margin: 0 auto;
    justify-content: center;
    padding: 0 0 50px 0;
}

.BookingFrame iframe{
    width: 423px;
    height: 350px;
    border: none;
    border-radius: 30px;
}


.ReqForBook{
    background-color: #ddf4ff62 ;
    padding: 20px;
}

.ReqForBook h1{
    font-size: 32px;
    text-align: center;
    padding-bottom: 10px;
}

.ReqForBook h2{
    font-size: 24px;
}
.ReqForBook p{
    font-size: 18px;
    white-space: pre-wrap;
}


