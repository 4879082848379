@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

.navHead{
    position: relative;
    height: 70px;
    max-width: 1279px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    background-color: white;
}


.HeaderMenu{
    padding-top:23.5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 75px;
}

.headerLinks{
    padding-right: 35px;
}

.phoneAndLaung{
    /*background-color: violet;*/

    padding-top:14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 12px;
}

.phone{
    border: 1px solid #bdbdbd;
    width: 42px;
    height: 42px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    display: flex;
  align-items: center;
  justify-content: center;
  margin-left:-1px;
}

.phone:hover{
    border:rgb(125,217,67,0.65);
}

.phone div{
    font-family: 'Roboto', sans-serif;
    width: 44px;
    height: 42px;
    display: flex;
  align-items: center;
  justify-content: center;
}



.phoneAndLaung img{
    width: 24px;
    height: 24px;
}


.headerLinks .ActivePage::after{
    content: '';
    display: block;
    height: 3px;
    width: 100%;
    background: transparent;
    border-radius: 2px;
    background: #4FB2D1;
}

.headerLinks .ActivePage{
    font-weight:450;
}

.super{
    display: inline-block;
    position: relative;
    padding-bottom: 3px;
}

.super:after {
    content: '';
    display: block;
    height: 3px;
    width: 0px;
    background: transparent;
    border-radius: 2px;
    transition: width .4s ease, background-color .4s ease;
  }

  .super:hover:after {
    width: 100%;
    background: #4FB2D1;
  }

  li{
      list-style: none;
      font-family: 'Roboto', sans-serif;
  }
  ul{
      display: flex;
      font-family: 'Roboto', sans-serif;
    justify-content: space-around;
  }

  .Laung{
      width :88px;
      height: 42px;
      border-top: 1px solid black;
      border-bottom:  1px solid black;
      margin-top: 0px;
      padding-left: 0px;
      display: none;

  }
  .bortik{
      border-right: 1px solid black ;
      width :44px;
      height: 41px;
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
      display: flex;
  align-items: center;
  justify-content: center;
  }
  .animat{
      display: flex;
      padding-right: 88px;
  }

  .animat:hover .Laung{
    display: flex;
  }

.animat:hover{
    padding-right: 0px;
}

li:hover{
   background-color: rgb(125,217,67,0.65);
}

li div{
    color:#707070;
}

li:hover div{
    color:white;
}

.animatPhone .phone div {
   width: 44px;
   height: 42px;
}

.animatPhone {
    margin-left: 20px;
    display:flex;
}

.Nomer{
    display:none;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid rgb(125,217,67,0.65);
    color:#313131;
    border-left: none;
    height: 42px;
}

.animatPhone:hover .Nomer{
    display: flex;
}

.animatPhone:hover{
    padding-right: 0px;
}

.Language_tell{

    display: flex;
    align-items: center;
    margin-left:10px;
}




.select{
    display: block;
    max-width: 215px;
    width: 100%;
    position: relative;
    border: 2px solid black;

    padding-top:8px;
    padding-bottom:11px;
    cursor: pointer;

}

.select .option:hover {
    background: black !important;
  }


  @media screen and (max-width: 480px) {
    .phoneAndLaung{
      display:none;
    }
    .HeaderMenu{
        display:none;
      }
      .ButtoWraper{
      }
      .navHead{
        justify-content: flex-end;
    }

  }

  @media screen and (max-width: 768px) {
    .phoneAndLaung{
        display:none;
    }
    .HeaderMenu{
        display:none;
      }
      .ButtoWraper{
    }
    .navHead{
        justify-content:flex-end;
    }

  }

  @media screen and (max-width: 1024px ) {
    .navHead{
        max-width: 1000px;

    }
    .phoneAndLaung{
       display:none;
    }
    .headerLinks{
        font-size: 12px;
    }
    .HeaderMenu{
        padding-left: 10px;
    }
    .headerLinks{
        padding-right: 20px;
    }
  }


  .styleForBurger{
  position: relative;
  display: block;
  text-align: left;
  max-width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 16%;
  background-position: 88% 50%;
  background-size: 36px;
  background-repeat: no-repeat;
  transition: background-position 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  text-decoration: none;
  color: #fff;
  font-size: 32px;
  line-height: 120%;
  font-weight: 500;
  }

  .styleForBurger:hover {
    background-position: 90% 50%;
  }
