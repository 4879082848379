.swiper-container {
    width: 100%;
    padding-top: 2%;
    padding-bottom: 5%;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 200px;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
  }