.AboutUsWrapper {
  width: 1297px;
  
  padding-top: 2%;
  padding-bottom: 2%;
  display: flex;
  justify-content: space-between;
}

.containerrr {
  padding-top: 70px;
  margin: 0 auto;
  max-width: 1297px;
}

.AboutUsTextWrapper {
  padding:30px;
  background-color: #EEF9FF;
  
  
  
  width: 50%;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AboutUsTitle {
  margin-top: 20px;
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
}



.AboutUsImg{
  width: 50%;
  
  overflow: hidden;
  padding: 0px;
}
@media screen and (max-width: 1300px) {
  
    
  .AboutUsWrapper{
    display: flex;
    
    width: 100%;
  }
  .AboutUsTextWrapper{
    width: 100%;
  }
  .AboutUsImg{
    width: 100%;  
  }

}



@media screen and (max-width: 1024px) {
  .containerrr{
    padding-top: 0px;
  }
  .AboutUsWrapper{
    padding-top: 0%;
  }
    
  .AboutUsWrapper{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .AboutUsTextWrapper{
    width: 100%;
    
  }
  .AboutUsImg{
    width: 100%;  
  }
  .AboutUsTextWrapper{
    padding-top: 0px ;
  }
}

@media screen and (max-width: 768px) {
  .containerrr{
    padding-top: 0px;
    
  }
  .AboutUsTitle {
    font-size: 21px;
  }
  .AboutUsWrapper img {
    width: 100%;
    height: 80%;
  }
  .AboutUsTextWrapper {
    
    width: 100%;
    padding-left: 3% ;
    padding-right: 3%;
    font-size: 17px;
  }
  .AboutUsWrapper {
    flex-wrap: wrap;
    padding-top: 0%;
  }
  .AboutUsTitle{
      margin-top: 1%;
      margin-bottom: 1%;
  }
  .AboutUsTextWrapper{
    padding-top: 0px ;
  }
  .AboutUsWrapper{
    padding-top: 0%;
  }
  .AboutUsTextWrapper{
    padding-top: 0px ;
  }
}

@media screen and (max-width: 480px) {
  .containerrr{
    padding-top: 0px;
  }
  .AboutUsTextWrapper{
    padding-top: 0px ;
  }
  .AboutUsTitle {
    font-size: 21px;
  }
  .AboutUsWrapper {
    flex-wrap: wrap;
    padding-top: 0%;
  }
  .AboutUsTextWrapper {
    
    
    font-size: 14px;
  }
  .AboutUsWrapper{
    padding-top: 0%;
  }
  .AboutUsTextWrapper{
    padding-top: 0px ;
  }
}


.textAboutUs{
  white-space: pre-wrap;
}