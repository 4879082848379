
.BookingFlameWrapper{
    background-image: url("../image/end/ForAll.jpg");
    background-repeat:no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display:block;
    margin: 0 auto;
    justify-content: center;
}

.BookingFlameWrapper .LogoForBooking{
    display:flex;
    margin: 0 auto;
    justify-content: center;
    padding: 50px 0 25px 0 ;
}

.BookingFrame{
    display:flex;
    margin: 0 auto;
    justify-content: center;
    padding: 0 0 50px 0;
}

.BookingFrame iframe{
    width: 423px;
    height: 350px;
    border: none;
    border-radius: 30px;
}


.ReqForBook{
    background-color: #ddf4ff62 ;
    padding: 20px;
}

.ReqForBook h1{
    font-size: 32px;
    text-align: center;
    padding-bottom: 10px;
}

.ReqForBook h2{
    font-size: 24px;
}
.ReqForBook p{
    font-size: 18px;
    white-space: pre-wrap;
}

