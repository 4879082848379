.menu {

  }
  

  .menu-item {
    border-bottom: 1px solid #9BC0D6;
  }
  
  .menu-item-a {
    display: block;
    padding: .7rem 15px;
    color: inherit;
    text-decoration: none;
  }
  
  .menu-item-a:hover {
    color: inherit;
    text-decoration: none;
    background-color: #eee;
  }
  
  .menu-item-a:focus {
    text-decoration: none;
  }
  
  .menu-item-a:visited {
    color: inherit;
  }
  
  .active-menu-item {
    background-color: #9BC0D6;
  }
  
  .hint {
    padding: .5rem 15px;
  }

  .menu-item a {
    display: block;
    padding: .7rem 15px;
    color: inherit;
    text-decoration: none;
  }
  
  .menu-item a:hover {
    color: inherit;
    text-decoration: none;
    background-color: #eee;
  }
  
  .menu-item a:focus {
    text-decoration: none;
  }
  
  .menu-item a:visited {
    color: inherit;
  }