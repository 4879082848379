.RecomendWrapper{
  margin: 0 auto;
  text-align: center;
  margin-bottom: 50px;
}

.RecomendWrapper img{
  width: 10%;
  height: auto;
}

.RecomendWrapper img:not(:first-child){
  margin-left: 4%;
}

.ReviewTitle{
  padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .RecomendWrapper img{
    width: 15%;
    height: auto;
  }
}

@media screen and (max-width: 480px) {
  .RecomendWrapper img{
    width: 20%;
    height: auto;
  }
}