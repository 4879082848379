.TextWrapper {
  width: 1100px;
  
  background-color: rgb(196, 196, 196, 0.2);
  padding: 55px;
  padding-bottom: 30px;

  margin: 0 auto;
  margin-bottom: 50px;
  margin-top: 40px;
  position: relative;
}

.Text {
  width: 980px;
  position: relative;
  padding-left: 20px;
}

.Text::before {
  content: " ";
  width: 0.5px;
  height: 80px;
  background-color: black;
  position: absolute;
  margin-left: -14px;
  margin-top: 8px;
}


/*.Text::after {
    content: " ";
    width: 360px;
    height: 0.5px;
    background-color: black;
    position: absolute;
    margin-left: -614px;
    top: -10px;
  }*/
  
  .LineText{
    width: 360px;
    height: 0.5px;
    background-color: black;
    position: absolute;
    margin-left: 40px;
    top: 38px;
  }