


.MainWordsInBack{
   font-size: 40px;
   font-weight:400;
   opacity: 100%;
   color: white;
   display:block;
   margin-bottom: 20%;
}

.WeatherInBook2{
  position: absolute;
  left:10%;
  top: 15%;
  z-index: 200;
}


.firstEl{
    margin-right: 60%;
    opacity: 0%;
    animation: an 10s infinite;
}

.SecondEl{
  opacity: 0%;
  animation: an2 10s infinite;
}

.ThreeEl{
  margin-left: 60%;
  opacity: 0%;
  animation: an3 10s infinite;
}

@keyframes an {
  0% {
    opacity: 0%;
  }
  15% {
    opacity:100%
  }
  100% {
    opacity: 100%
  }
}
@keyframes an2 {
  0% {
    opacity: 0%;
  }
  15% {
    opacity: 0%;
  }

  30% {
    opacity:100%
  }
  100% {
    opacity: 100%
  }
}
@keyframes an3 {
  0% {
    opacity: 0%;
  }
  30% {
    opacity:0%
  }
  45% {
    opacity:100%
  }
  100% {
    opacity: 100%
  }
}

@media screen and (max-width: 1024px ) {
  .MainWordsInBack{
    font-size: 20px;
  }

}

@media screen and (max-width: 768px) {
  .MainWordsInBack {
    font-size: 18px;
    margin-bottom: 10%;
  }
}

@media screen and (max-width: 480px) {
  .MainWordsInBack {
    font-size: 10px;
  }
}
