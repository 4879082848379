.CardSlider img {
  width: 420px;
  height: 280px;
}

.CardSlider {
  margin-bottom: 30px;
  text-align: center;
}

.AllInSliderMain {
  margin: 0 auto;
  max-width: 1440px;
  display: block;
  padding-bottom: 10px;
  padding-top: 50px;
}

.CottegesAndBlabLa {
  text-align: center;
  font-weight: 600;
  font-size: 28px;
  text-transform: uppercase;
  padding-bottom: 50px;
}

.WordsInSliderMain {
  text-align: center;
  width: 400px;
  padding-top: 5px;
}

.SliderForSmall {
  display: none;
}

.SliderForSmall123 {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.MargineSlider{
  margin: 0 auto;
}

.SliderForSmallest {
  display: none;
}

@media screen and (max-width: 1024px) {
  .SliderForBig {
    display: none;
  }

  .SliderForSmallest {
    display: none;
  }
  .SliderForSmall {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .SliderForBig {
    display: none;
  }
  .SliderForSmallest {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .SliderForSmall {
    display: none;
  }

  

}

@media screen and (max-width: 768px) {
  .SliderForBig {
    display: none;
  }

  .SliderForSmallest {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .SliderForSmall {
    display: none;
  }
}
