
.et{
  max-width: 1297px;
}

 .icon-style {
  font-size: 100px;
  color: rgb(255, 255, 255);
}




i:hover {
 
  animation: shake 1.5s;

}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
 
  30% { transform: translate(3px, 2px) rotate(0deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
}


.Temperature h2{
  color:white;
  font-size: 18px;
  margin-left: 30%;
}

.Temperature i{
  margin-top: 2%;
}

@media screen and (max-width: 1024px) {
  .Temperature h2{
    display: none;
  }
  .Temperature i{
    display: none;
  }
   }
