.KeyForBook{
    width: 150px;
    height: auto;
}

.KeyForBook img{
    position: fixed;
    z-index: 500;
    bottom: 30px;
    right: 30px;
    width: 80px;
    height: auto;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;

  }

.KeyForBook img:hover{
    transform: scale(1.05);

}


@media screen and (max-width: 768px) {
    .KeyForBook{
        width: 100px;
        height: auto;
    }
    .KeyForBook img{
        width: 75px;
        height: auto;
        right: 20px;
      }
      .KeyForBook img:hover{
          transform: scale(1.05);

    }
  }

  @media screen and (max-width: 480px) {
    .KeyForBook{
        width: 80px;
        height: auto;
    }
    .KeyForBook img{
        width: 60px;
        height: auto;
        right: 15px;
      }
      .KeyForBook img:hover{
        width: 70px;

    }
  }
