@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.KeyForBook{
  position: fixed;
  z-index: 500;
}

div{
    font-family: 'Montserrat Alternates';
    display: block;
}
.ButtoWraper button {
    background-color: white;
    width: 42px;
    height: 42px;
  border: 1px solid #9BC0D6;
  padding: 8px;
  font-family: "Roboto";
  font-weight: bold;
}

.ButtonLanguage {
  margin-right: 10px;
}

.ButtonLanguage:hover {
  background-color: #9BC0D6;
  color: white;
}

#something:target {
  background-color: black;
  color: white;
}


button:focus{
    background-color: black;
    color: white;
 }

.burgerOk{
  display:none;
  top: 17px;
  left: 30px;
  margin-top: 1%;
}


@media screen and (max-width: 768px) {
  .burgerOk{
    display:flex;
    position: fixed;
    z-index: 200;
  }
}

@media screen and (max-width: 480px) {
  .burgerOk{
    display:flex;
  }
}
