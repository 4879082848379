.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.MainSwiperReview {
  width: 40%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 50px;
}

.SlideReview {
  background-color: #eef9ff;
  padding-top: 5% ;
  width: 100%;
  height: 400px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.SlideReview img {
  border-radius: 50%;
}

.SlideReview p {
  margin-top: 1%;
  font-size: 18px;
  margin-left: 2%;
}

.ReviewTitle {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 600;
}

.SlideText {
    text-align: left;
  width: 80%;
  margin: 0 auto;
  font-size: 15px;

}


.SlidTitleAva{
    font-size: 22px;
    font-weight: bold;
}


@media screen and (max-width: 1024px) {
  .MainSwiperReview {
    width: 576px;
  }
}

@media screen and (max-width: 768px) {
  .MainSwiperReview {
    width: 480px;
  }
}

@media screen and (max-width: 480px) {
  .MainSwiperReview {
    width: 100%;
  }
}
