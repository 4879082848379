.BenefistWrapper{
  max-width: 750px;
  display:flex;
  margin: 0 auto;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 50px;
}


.firstJardWrapper{
 display: flex;
}

.SimpleJard img{
  margin-top: 10px;
}

.SimpleJard h1{
  font-size: 18px;
  margin-top: 10px;
}

.SimpleJard{
  display: flex;
  justify-content: center;
  align-items: center;
 width: 200px;
 height: 200px;
 background-color: white;
 text-align: center;
 position: relative;
 -webkit-transition: all 0.5s ease;
   -moz-transition: all 0.5s ease;
   -o-transition: all 0.5s ease;
}

.SimpleJard:hover{
  border: 1px solid rgb(188, 214, 248);
  transform: scale(1.05); 
  opacity: 100%;
 z-index: 50;
 -webkit-box-shadow: 0px 0px 20px rgba(177, 234, 248, 0.8);
 -moz-box-shadow: 0px 0px 20px rgba(177, 234, 248, 0.8);
 box-shadow: 0px 0px 20px rgba(177, 234, 248, 0.8);

}

@media screen and (max-width: 480px) {
  .SimpleJard{
    width: 135px;
 height: 135px;
  }
  .SimpleJard img{
    width:65px;
  }
  .SimpleJard h1{
    font-size: 12px;
  }
}

@media screen and (max-width: 380px) {
  .SimpleJard{
    width: 105px;
 height: 105px;
  }
  .SimpleJard img{
    width:50px;
  }
  .SimpleJard h1{
    font-size: 10px;
  }
}