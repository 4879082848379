.firstPar {
  background-color: rgb(196, 196, 196, 0.5);
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  height: 60px;
  display: flex;
}

.secondPar {
  background-color: rgb(196, 196, 196, 0.5);
  margin-top: 30px;
  margin-bottom: 30px;
  height: 60px;
  display: flex;
  justify-content: flex-end;
}

.firstPar p {
  font-size: 30px;
  color: #000000;
  opacity: 70%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 40px;
}

.secondPar p {
  font-size: 30px;
  color: #000000;
  opacity: 70%;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 40px;
}

.allContact {
  margin: 0 auto;
  max-width: 1279px;
  margin-bottom: 30px;
}

.TextInService {
  height: 500px;
  width: 50%;
  background-color: rgb(196, 196, 196, 0.2);
  font-size: 19px;
  padding-left: 90px;
  padding-top: 30px;
}

.imageService {
  width: 50%;
  height: 500px;
}

.ServiseWrapper {
  display: flex;
}

/* Адаптив  */

.textServ {
  max-width: 400px;
}

.Sauna2 {
  margin-top: 20px;
}

/*Адаптив---------------*/
@media screen and (max-width: 1024px) {
  .TextInService{
    padding-top: 5%;
    padding-left: 5%;
    min-height: 100%;
  }
  .textServ{
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .ServiseWrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .TextInService {
    width: 100%;
    padding-left: 70px;
    padding-top: 40px;
    padding-bottom: 40px;
    height: 100%;
    
  }
  .textServ{
    max-width: 600px;
    
      width: 95%
    
  }
  .imageService {
    width: 100%;
    height: 100%;
  }
  .LongTextFirstPar{
    height: 100px;
  }
}

@media screen and (max-width: 480px) {
  .BackgroundWrapper{
    height: 100%;
  }
  .firstPar p {
    margin-left: 20px;
    font-size: 25px;
    
  }
  .firstPar {
    margin-top: 10px;
    margin-bottom: 10px;
    height:100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .secondPar {
    margin-top: 10px;
    margin-bottom: 10px;
    
  }
.textServ{
  font-size: 15px;
  width: 95%
}
  .secondPar p {
    font-size: 25px;
  }
  .ServiseWrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .TextInService {
    width: 100%;
    padding-left: 30px;
    padding-top: 40px;
    padding-bottom: 40px;
    height: 100%;
  }
  .imageService {
    width: 100%;
    height: 100%;
  }
  .LongTextFirstPar{
  
    height:100%;
    padding: 3px;
    padding-left: 20px;
  }
}
