div .UpperFooterPar {
  display: flex;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}

div .UpperFooterContent {
  display: flex;
  justify-content: space-between;
}

.UpperFooterContacts img {
  width: 24px;
  height: 24px;
  color: black;
}

.UpperFooterItem {
  max-width: 1163px;
  margin: 0 auto;
}

.FooterItems {
  margin-left: auto;
  margin-right: auto;
}

.FooterItems::before {
  content: "";

  background-color: #566f7d;
  width: 1.5px;
  height: 150px;
  position: absolute;
  margin-top: 135px;
  margin-left: 400px;
  opacity: 80%;
}
.FooterItems:last-child:before {
  content: "";
  opacity: 0%;
}

.TextContacts {
  max-width: 312px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  text-transform: none;
}

.TextContacts {
  display: flex;
  align-items: flex-start;
  margin-left: 16px;
  margin-bottom: 10px;
}

.TextContacts.div {
  display: block;
}

.fixCenter {
  margin-left: -15px;
}

.TextContacts img {
  height: 22px;
  width: 22px;
  margin-right: 5px;
}

.BookingImg {
  height: 60%;
  width: 80%;
  margin-right: 2%;
}

.PlanetHotel {
  height: 60%;
  width: 60%;
}

.PlanetImg {
  display: flex;
  align-items: center;
}

.IconFooter {
  display: flex;
  justify-content: space-between;
  width: 100px;
}

.Icons img {
  padding-top: 15px;
  width: 35px;
  height: 35px;
  padding-right: 20px;
}

.Icons:first-child {
  padding-left: 10px;
}

/*--------------------*/
.AllInUpperFooter {
  background-color: hsl(204, 71%, 97%);
  padding-top: 0px;
  padding-bottom: 20px;
}

.UpperFooterWrapper {
  margin: 0 auto;
  max-width: 1163px;
  display: flex;
}

.UpperFooterIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33.3%;
}

.FixKurw {
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  /* margin-top: 30px;
  margin-left: 110px;
  margin-bottom: 20px; */
}
.Centertext {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  text-align: center;
}

.TextContaxtWrapper {
  margin-left: 0px;
  position: relative;
}
.WrapperTextLiving {
  margin-left: -60px;
}

/*-------Адаптив--*/
@media screen and (max-width: 480px) {
  .UpperFooterWrapper {
    max-width: 480px;
    display: flex;
    flex-wrap: wrap;
  }
  .UpperFooterIcon {
    width: 100%;
  }
  .FixKurw {
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
  }

  .TextContacts {
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    margin-left: 16px;
    margin-bottom: 10px;
  }
  .TextContacts a {
    font-size: 17px;
  }
  .WrapperTextLiving {
    margin-left: -60px;
  }
}

@media screen and (max-width: 700px) {
  .UpperFooterWrapper {
    max-width: 480px;
    display: flex;
    flex-wrap: wrap;
  }
  .UpperFooterIcon {
    width: 100%;
  }
  .nav {
    max-width: 768px;
    max-height: 110px;
  }
  .TextContacts {
    font-size: 14px;
  }

  .TextContacts a {
    font-size: 14px;
  }

  
}


@media screen and (max-width: 900px) {
  
  .nav {
    max-width: 768px;
    max-height: 110px;
  }
  .TextContacts {
    font-size: 14px;
  }

  .TextContacts a {
    font-size: 14px;
  }

  
}

@media screen and (max-width: 1024px) {
  .nav {
    max-width: 992px;
    max-height: 110px;
  }
}
