.ImgBackground2 {
    padding-top: 10px;
        margin-top: -115px;
         
    }
    .Imgas2 {
      margin: 0 auto;
    }
    
    .Background2 {
      width: 100%;
      height: 80%;
    }
    
    .LogoDiv2 img {
        width: 100%;
      margin-top: 175px;
      position: relative;
      
    }
    .LogoDiv2{
        margin: 0 auto;
        width: 20%;
        height:112px;
        
    }
    
    .BackgroundWrapper{
      height: 250px;
      max-width: 100%;
      overflow: hidden;
    }