.allInContact2{
    width: 40%;
    height: 40%;
}

.allInCon2{
    padding-top: 30px;
    padding-bottom: 30px;
}


.allInCon2{
    display: flex;
    max-width: 1279px;
    justify-content: space-around;
    margin: 0 auto;

}

.infoContact2{
    width: 40%;
    height: 40%;
    background-color: rgb(125,217,67,0.8);
    border-radius: 15px;
    
}


.infoContact2{
    color: white;
    font-size: 34px;
    
}

.TextContacts2{
    font-size: 22px;
    display: flex;
    padding-bottom: 25px;
}

.MainContText2{
    padding-bottom: 30px;
    padding-top: 30px;
    text-align: center;
}

.TextContaxtWrapper2{
    margin-left: 5%;
    position: relative;
    
}

.UpperFooterContacts2{
    padding-right: 5%;
}

@media screen and (max-width: 1024px ) {
    .allInCon2{
        flex-direction: column;
        align-items: center;
    }
    .infoContact2{
        margin-bottom: 10px;
        width: 60%;
    height: 60%;
    }
    .TextContaxtWrapper2{
        margin-left: 15%;
        
        
    }
    .allInContacts{
        max-width: 1024px;
        
    }
    .allInContact2{
        width: 60%;
        height: 60%;
    }

  }
  
  @media screen and (max-width: 768px) {
    .allInCon2{
        margin-top: 0px;
        flex-direction: column;
    }
    .infoContact2{
        margin-bottom: 20px;
        width: 70%;
    height: 70%;
    }
    .TextContaxtWrapper2{
        margin-left: 5%;
        
        
    }
    .allInContacts{
        max-width: 768px;
        
    }
    .allInContact2{
        width: 70%;
        height: 70%;
    }
  }
  
  @media screen and (max-width: 480px) {
    .allInCon2{
        flex-direction: column;
        margin-top: -30px;
        
    }
    .infoContact2{
        width: 90%;
    height: 90%;
        margin-bottom: 10px;
    }
    .allInContact2{
        width: 90%;
        height: 90%;
    }
    .allInContacts{
        max-width: 480px;
        
    }
  }