.nav{
    background-color: #9BC0D6;
    max-height: 58px;
    padding-bottom: 20px;
    
}


.text{
    color: black;
    font-size: 13.8px;
    text-align: center;
    padding-top: 5px;
    font-size: medium;
    margin-bottom: 0px;
}

.footerMenu{
    text-align: center;
    font-size: 13.8px;
    margin:0 auto;
}

.footerSimpleLinks{
    display: inline-block;
    padding-right: 20px;
}
.footerSimpleLinks::before{
    content: "";
    background-color: #566F7D;
    width: 25px;
    height: 1px;
    position: absolute;
    margin-top: 20px;
}

a{
    color: black;
    text-decoration: none;
}
.specialForService::before{
    content: "";
    background-color: #566F7D;
    width: 25px;
    height: 1px;
    margin-top: 20px;
}

@media screen and (max-width: 480px) {
  .nav{
    max-width: 480px;
    max-height: 150px;
  }
}

@media screen and (max-width: 768px) {
  .nav{
    max-width: 768px;
    max-height: 100px;
  }
}

@media screen and (max-width: 1024px ) {
  .nav{
    max-width: 992px;
    max-height: 80px;
  }
}