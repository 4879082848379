    .BookingTitle3WithBalcon{
        font-family: Montserrat Alternates;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 49px;
        text-align: center;
    }
    


/*-------------------
Стилі до заголовків на різних сторінках
(Без них вони криві :)))  )
*/
      .BookingTitleMans{
        font-family: Montserrat Alternates;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 49px;
        text-align: center;
    }





      .BookingTitleCoteg{
        font-family: Montserrat Alternates;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 49px;
        text-align: center;
    }

